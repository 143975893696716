<template>
  <div class="col-12 col-lg-10 c g">
    <div class="card card-body">
        <img :src="require('@/assets/images/loading.svg')" v-if="loading" width="50">
        <div class="col-12 table-responsive">
            <div class="g">
                <button class="btn btn-primary" @click="addDevice()">
                    <i class="fa fa-plus"></i> اضافة جهاز جديد
                </button>
            </div>
            <table class="table table-hover table-bordered">
                <thead>
                    <th>
                        العنوان
                    </th>
                    <th>
                        الهاتف
                    </th>
                    <th>
                        حالة الاتصال
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="device in devices" :key="device._id">
                        <td>
                            {{ device.title }}
                        </td>
                        <td>
                            {{ device.phone }}
                        </td>
                        <td>
                            <span
                            class="badge bg-warning"
                            v-if="device.status == 0"
                            >
                            <i class="fa fa-clock-o"></i>
                            لم يتصل بعد
                            </span>
                            <span
                            class="badge bg-success"
                            v-if="device.status == 1"
                            >
                            <i class="fa fa-check"></i>
                            متصل الآن
                            </span>
                            <span class="badge bg-danger" v-if="device.status == 2">
                            <i class="fa fa-times"></i>
                            قُطع الاتصال
                            </span>
                            <span class="badge bg-danger" v-if="device.status == 3">
                            <i class="fa fa-times"></i>
                            الرقم جديد!ّ ربما يتم حظره اذا تم الارسال منه
                            </span>
                        </td>
                        <td>
                            <button
                            v-if="device.status == 0"
                            class="btn btn-sm btn-success"
                            @click="current_device = device"
                            v-b-modal.modal-qrcode
                            >
                            <i class="fa fa-qrcode"></i>
                            إتصال / فحص الجهاز
                            </button>&nbsp;
                            <button
                            class="btn btn-sm btn-danger"
                            @click="deleteDevice(device._id)"
                            >
                            <i class="fa fa-trash"></i>
                            </button>&nbsp;
                            <button
                            class="btn btn-sm btn-info" title="اعادة تشغيل"
                            @click="restart(device._id)"
                            v-if="device.status == 1"
                            >
                            <i class="fa fa-refresh"></i>
                            </button>&nbsp;
                            <button
                            class="btn btn-sm btn-success" title="الدخول للمحادثة"
                            @click="gotochat(device._id)"
                            v-if="device.status == 1"
                            >
                            <i class="fa fa-comments"></i>
                            </button>&nbsp;
                            <button
                            class="btn btn-sm btn-success"
                            @click="clean(device._id)"
                            v-if="device.status == 2 || device.status == 3"
                            >
                            <i class="fa fa-qrcode"></i>
                            إعادة الفحص من جديد
                            </button>
                            <button
                            class="btn btn-sm btn-info"
                            @click="cc(device._id)"
                            >
                            <i class="fa fa-id-card-o"></i>
                            رقم الجهاز
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- basic modal -->
    <b-modal
    id="modal-qrcode"
    v-if="current_device.qrcode"
    title="اتصال الجهاز"
    hide-footer
    >
    <div class="text-center col-12">
        <img
        v-if="current_device.qrcode.includes('https')"
        :src="current_device.qrcode"
        style="width: 50%; margin: 0px auto"
        />
        <img
        v-if="!current_device.qrcode.includes('https')"
        :src="qrcodeaftergenerate"
        style="width: 50%; margin: 0px auto"
        />
    </div>
    <br />
    <p></p>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  beforeDestroy() {
    if (this.inertValDevices) {
      clearInterval(this.inertValDevices);
    }
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem('user')),
      loading: false,
      devices: [],
      inertValDevices: null,
      current_device: {},
      qrcodeaftergenerate: ""
    }
  },
  created(){
    var g = this;
    this.loading = true
    g.getDevices()
    g.inertValDevices = setInterval(() => {
      if (g.current_device) {
        g.devices.forEach(async (element) => {
          if (g.current_device._id == element._id) {
            g.current_device = JSON.parse(JSON.stringify(element));
            g.qrcodeaftergenerate = await QRCode.toDataURL(
              g.current_device.qrcode
            );
          }
        });
      }
      g.getDevices(true);
    }, 5000);
  },
  methods: {
    cc(id){alert(id)},
    gotochat(id){
      window.location = '/chat#' + id
    },
    getDevices(){
    var g = this;
    $.post(api + '/user/devices/list', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.devices = r.response
            }
        }).fail(function(){
            g.loading = false
        })
    },
    deleteDevice(id) {
      var g = this;
      if (confirm("متأكد من حذف الجهاز؟")) {
        $.post(api + "/user/devices/delete", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function () {
            g.getDevices();
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
    restart(id) {
      var g = this;
      if (confirm("متأكد من اعادة تشغيل الخدمة؟")) {
        $.post(api + "/user/devices/restart", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function () {
            g.getDevices();
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
    clean(id) {
      var g = this;
      if (confirm("متأكد من اعادة الفحص من جديد؟")) {
        $.post(api + "/user/devices/clean", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function () {
            g.getDevices();
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
    addDevice(){
        var g = this;
        var title = prompt("اكتب عنوان للجهاز:", "جهاز رقم #" + Math.random().toString().split(".")[1].substring(0,4))
        if(title){
        $.post(api + '/user/devices/add', {
            jwt: this.user.jwt,
            title: title
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                location.reload()
            }
        }).fail(function(){
            g.loading = false
        })
        }
    }
  }
}
</script>

<style>

</style>